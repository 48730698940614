// custom variable
$wkPrimary: #a6ebea;
$wkSecondary: #20cdcb;
$wkSuccess: #16a34a;
$wkpInfo: #13101c;
$wkWarning: #945707;
$wkDanger: #ff4747;
$wkLight: #d2f5f5;
$wkDark: #020202;

$wkGray: #ebebeb;
$wkBolderGray: #a0a0a0;

//set varibale
$primary: $wkPrimary;
$secondary: $wkSecondary;
$success: $wkSuccess;
$info: $wkpInfo;
$warning: $wkWarning;
$danger: $wkDanger;
$light: $wkLight;
$dark: $wkDark;
