/* You can add global styles to this file, and also import other style files */
@use 'wakatoon.theme' as wktTheme;
@use 'wakatoon.variable' as wktVar;

@import url('https://fonts.googleapis.com/css?family=Comfortaa&display=swap');
/* Font Family */

html,
body {
  height: 100%;
  //width: 100%;
  overflow-x: hidden !important;
  font-family: Comfortaa;
}

body {
  padding: 0;
}

.main-page {
  height: 100%;
  overflow-x: hidden !important;
  //max-width: 375px;
  margin: 0 auto;
  //border: 1px solid lightgray; // TODO
  //.navbar {
  //max-width: 375px;
  //margin: 0 auto;
  // }
}

.main-content {
  padding-top: 56px;
  height: calc(100% - 56px);
}

.border-left-radius {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.border-right-radius {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

/* Grid Layout */

.border-zone {
  border: 1px solid red;
}

.boder-wkBolderGray {
  border-color: wktVar.$wkBolderGray !important;
  color: wktVar.$dark !important;
}
/*end zone */

.border-left {
  border-left: 1px solid black;
}

/* Logo */
.wkt-logo {
  display: block;
  margin: auto auto;
}

.wkt-logo-store {
  max-width: 50px !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25%;
}

#wkt-logo {
  display: block;
  margin: 0 auto;
  max-width: 50%;
}

/* end zone */

/* Buttons */


.wkt-button-secondary-color-white {
  color: white !important;
}

/* end zone */

/* icons */
.chevron-class {
  position: absolute;
  color: #a0a0a0;
  cursor: pointer;
}

.bi::before {
  color: #a0a0a0;
}

.text-wkGray {
  color: #a0a0a0;
}

/* end zone */

@media (min-width: 750px) {
  .tablet-screen-responsive {
    max-width: 375px;
    margin: 0 auto !important;
  }

  .wkt-button {
    //color: white;
    height: 52px;
  }
}

@media (max-width: 750px) {
  .tablet-screen-responsive {
    max-width: 100%;
  }

  .border-xs-left {
    border-left: 1px solid black;
  }

  .wkt-button {
    //color: white;
    height: 52px;
    font-size: 1rem;
  }
}

/* form control */
.form-control{
  &.ng-dirty{
    &.ng-invalid:not(form){
        border: 1px solid #a94442 !important;

    }
  }

  &:focus {
    &.ng-dirty{
      &.ng-invalid:not(form){
          border-bottom: 1px solid #a94442 !important;

      }
      &.ng-valid:not(form)  {
        border-bottom: 1px solid #42A948 !important;

    }
 }
 &.ng-dirty{
  &.ng-valid:not(form)  {
    border: 1px solid #42A948 !important;

  }
 }
}
}

.form-control:focus, .btn-outline-wkGray:hover, .btn-outline-dark:hover  {
  color: wktVar.$dark !important;
  background-color: #fff;
  //border-color: #80bdff;
  outline: none;
  box-shadow: none;
}
