@import 'wakatoon.variable';

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
  // add any additional color below
);

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/mixins';

$custom-theme-colors: (
  'wkGray': $wkGray,
  'wkBolderGray': $wkBolderGray,
);

$theme-colors: map-merge($theme-colors, $custom-theme-colors);
$theme-colors: map-merge($theme-colors, $custom-theme-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, '$value');
$utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
$utilities-text-colors: map-loop($utilities-colors, rgba-css-var, '$key', 'text');
$utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, '$key', 'bg');

@import '~bootstrap/scss/bootstrap';
@import '../../../node_modules/bootstrap-icons';
@import '../../../node_modules/flag-icons/sass/flag-icons.scss';

.bg-wkGray {
  background-color: var(--bs-wkGray);
}
